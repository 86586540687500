import React from 'react'
import Layout from '../../templates/layout'
import Hero from '../../components/Hero'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'

type Props = {
	data: {
		markdownRemark: {
			frontmatter: {
				slug: string
				title: string
				description: string
			}
		}
		allPrismicTeamMembers: {
			nodes: [
				{
					uid: string
					data: {
						biography: {
							text: string
						}
						image: {
							url: string
						}
						name: string
					}
				},
			]
		}
	}
}

const Index = ({ data }: Props) => {
	const teamMembers = data.allPrismicTeamMembers.nodes
	const metaTags = data.markdownRemark.frontmatter

	return (
		<Layout border={true}>
			<SEO
				title={metaTags.title}
				description={metaTags.description}
				path={metaTags.slug}
			/>
			<Hero
				links={[
					<Link to='/' className='mr-6 text-small text-grey' key='home-link'>
						Ana Sayfa
					</Link>,
					<Link
						to='/ekibimiz'
						className='font-medium text-small text-grey'
						key='our-team-link'
					>
						Ekibimiz
					</Link>,
				]}
				header='Ekibimiz'
				description='Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
			/>
			<div className='sm:flex justify-between items-center lg:flex-nowrap sm:flex-wrap lg:w-8/12 mx-auto lg:mb-20 mb-10'>
				{teamMembers.map((member, m) => {
					return (
						<div
							className='flex sm:flex-col items-center md:mb-0 mb-8 sm:w-1/3'
							key={member.uid}
						>
							<Link to={`/${member.uid}`}>
								<img src={member.data.image.url} alt='' />
							</Link>
							<div className='font-poppins font-medium text-lg sm:mt-8 sm:ml-0 ml-8'>
								<Link to={`/${member.uid}`}>{member.data.name}</Link>
							</div>
						</div>
					)
				})}
			</div>
		</Layout>
	)
}
export const query = graphql`
	query TeamMembersQuery {
		markdownRemark(fileAbsolutePath: { regex: "/team.md/" }) {
			frontmatter {
				slug
				title
				description
			}
		}
		allPrismicTeamMembers {
			nodes {
				uid
				data {
					biography {
						text
					}
					image {
						url
					}
					name
				}
			}
		}
	}
`
export default Index
